import React from 'react';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';

import { FaUserAlt } from 'react-icons/fa';

import config from '@config';
import { history } from '@src/resource/history';
import { socketconnect } from '@action/auth/user';
import AdminDetailModal from './adminDetailModal';

import "@assets/scss/simpleStyle.scss";

class Header extends React.Component {
        
    constructor() {
        super()
        this.state = {
            adStatus: false
        }
    }
    
    UNSAFE_componentWillMount() {
        this.props.socketconnect('admin');
    }
    
    gotopage(num) {
        if( num === 0 ) {
            history.push('/admin/dashboard');
        } else if( num === 1 ) {
            history.push('/admin/users');
        } else if( num === 2 ) {
            history.push('/admin/deposit');
        } else if( num === 3 ) {
            history.push('/admin/withdraw');
        } else if( num === 4 ) {
            history.push('/admin/buy');
        } else if( num === 5 ) {
            history.push('/admin/sell');
        } else {
            const cookies = new Cookies();
            if(config.dev === true) {
                cookies.remove('adtoken', { path: '/', domain: 'localhost' });
            } else {
                cookies.remove('adtoken', { path: '/', domain: 'hellenium.com' });
            }
            history.push('/admin/signout');
        }
    }

    onAToggle() {
        this.setState({adStatus: !this.state.adStatus });
    }

    render () {
        return (
            <>
                <AdminDetailModal
                    adStatus={this.state.adStatus}
                    onAToggle={() => this.onAToggle()}
                />
                <div id="kt_header" className="header header-fixed left-0">
                    <div className="container-fluid d-flex align-items-stretch justify-content-between">
                        <div className="topbar pt-4 pb-4">
                            <span className="symbol-label font-size-h5 font-weight-bold">
                                <span className="btn btn-light-primary btn-bold align-items-center mr-3" onClick={() => this.gotopage(0)}> Admin Panel </span>
                            </span>
                        </div>
                        <div className="topbar pt-4 pb-4">
                            <span className="symbol-label font-size-h5 font-weight-bold">
                                <button className="btn btn-light-primary btn-bold align-items-center mr-3" onClick={() => this.gotopage(1)}>User manage</button>
                            </span>
                            <span className="symbol-label font-size-h5 font-weight-bold">
                                <button className="btn btn-light-primary btn-bold align-items-center mr-3" onClick={() => this.gotopage(2)}>Deposit Request</button>
                            </span>
                            <span className="symbol-label font-size-h5 font-weight-bold">
                                <button className="btn btn-light-primary btn-bold align-items-center mr-3" onClick={() => this.gotopage(3)}>Withdraw Request</button>
                            </span>
                            <span className="symbol-label font-size-h5 font-weight-bold">
                                <button className="btn btn-light-primary btn-bold align-items-center mr-3" onClick={() => this.gotopage(4)}>Buy View</button>
                            </span>
                            <span className="symbol-label font-size-h5 font-weight-bold">
                                <button className="btn btn-light-primary btn-bold align-items-center mr-3" onClick={() => this.gotopage(5)}>Sell View</button>
                            </span>
                            <span className="symbol-label font-size-h5 font-weight-bold">
                                <button className="btn btn-light-primary btn-bold align-items-center mr-3" onClick={() => this.onAToggle()}><FaUserAlt /></button>
                            </span>
                            <span className="symbol-label font-size-h5 font-weight-bold">
                                <button className="btn btn-light-primary btn-bold align-items-center mr-3" onClick={() => this.gotopage(6)}>Sign Out</button>
                            </span>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default connect(null, { socketconnect })(Header);