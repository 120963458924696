import React, { useEffect, useState } from "react";
import {
    Modal,
    Form,
    FormGroup,
    FormLabel,
    FormControl,
    Button,
} from "react-bootstrap";
import Cookies from "universal-cookie";
import { customToast } from '@src/resource/toast';

import { changePass } from '@action/admin';
const cookies = new Cookies();

const AdminDetailModal = ({ adStatus, onAToggle }) => {
    const [adminRole, setAdminRole] = useState(0);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [npassword, setNpassword] = useState("");
    const [ncpassword, setNcpassword] = useState("");

    const onChangePass = async () => {
        if (password === "" || npassword === "" || ncpassword === "") {
            customToast("Please input correctly.", "error");
        } else if (password === npassword) {
            customToast("New password is same as currently password.", "error");
        } else if (npassword !== ncpassword) {
            customToast("New password and Confirmation doesn't match.", "error");
        } else {
            var changeResult = await changePass(password, npassword, email);
            if (changeResult.data.status === true) {
                customToast("Password changed.", "success");
                onAToggle();
            } else {
                customToast("Password changed.", "error");
            }
        }
    };

    useEffect(() => {
        try {
            const adtoken = cookies.get("adtoken");
            var dt = JSON.parse(atob(adtoken));
            setAdminRole(dt.role);
            setEmail(dt.email);
        } catch (err) {
            console.log(err);
        }
    }, []);

    return (
        <>
            <Modal show={adStatus} onHide={onAToggle} animation={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {adminRole === 0 ? "Super Admin" : "Normal Admin"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <FormGroup className="mb-3">
                            <FormLabel>Email</FormLabel>
                            <FormControl
                                type="text"
                                value={email}
                                disabled
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <FormLabel>Current Password</FormLabel>
                            <FormControl
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <FormLabel>New Password</FormLabel>
                            <FormControl
                                type="password"
                                onChange={(e) => setNpassword(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <FormLabel>Confirm Password</FormLabel>
                            <FormControl
                                type="password"
                                onChange={(e) => setNcpassword(e.target.value)}
                            />
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onChangePass}>
                        Change
                    </Button>
                    <Button variant="secondary" onClick={onAToggle}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AdminDetailModal;
