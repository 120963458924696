import axios from 'axios';
import config from '@config';
import { getSession, removeSession } from '../session';

export const AXIOS_REQUEST = async (url, data) => {
    try {
        const instance = axios.create({
            baseURL: config.server_url,
            headers: {
                'authorization': `${getSession()}`,
                "Content-Type": "application/json"
            }
        });

        const response = await instance.post(url, data);
        if (!response.data.status && response.data.isSession === false) {
            removeSession();
            window.location.reload();
        } else {
            return response.data;
        }
    } catch (error) {
        return { status: false, data: "network error" }
    }
}