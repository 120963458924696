import { combineReducers } from "redux"
import auth from "./auth";
import user from "./user";
import stellar from "./stellar";
import socket from "./socket";
import admin from "./admin";
import wallet from './wallet';

const rootReducer = combineReducers({
    auth,
    user,
    stellar,
    socket,
    admin,
    wallet
})

export default rootReducer
