import React from "react"
import "@assets/scss/app-loader.scss"
import "@assets/scss/loader.scss"
class ComponentSpinner extends React.Component {
  render() {
    return (
      // <div className="fallback-spinner">
      //   <div className="loading component-loader">
      //     {/* <span className="pr-4"><div className="spinner-grow text-primary"></div></span> */}
      //     <div className="effect-1 effects"></div>
      //     <div className="effect-2 effects"></div>
      //     <div className="effect-3 effects"></div>
      //   </div>
      // </div>
      
      <div className="preloader">
        <div className="preloader__ring">
          <div className="preloader__sector">S</div>
          <div className="preloader__sector">t</div>
          <div className="preloader__sector">e</div>
          <div className="preloader__sector">l</div>
          <div className="preloader__sector">l</div>
          <div className="preloader__sector">a</div>
          <div className="preloader__sector">r</div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector">S</div>
          <div className="preloader__sector">o</div>
          <div className="preloader__sector">n</div>
          <div className="preloader__sector">i</div>
          <div className="preloader__sector">c</div>
          <div className="preloader__sector">e</div>
          <div className="preloader__sector">S</div>
          <div className="preloader__sector">o</div>
          <div className="preloader__sector">n</div>
          <div className="preloader__sector">i</div>
          <div className="preloader__sector">c</div>
          <div className="preloader__sector">e</div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector">L</div>
          <div className="preloader__sector">T</div>
          <div className="preloader__sector">D</div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
        </div>
        <div className="preloader__ring">
          <div className="preloader__sector">H</div>
          <div className="preloader__sector">e</div>
          <div className="preloader__sector">l</div>
          <div className="preloader__sector">l</div>
          <div className="preloader__sector">e</div>
          <div className="preloader__sector">n</div>
          <div className="preloader__sector">i</div>
          <div className="preloader__sector">u</div>
          <div className="preloader__sector">m</div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
          <div className="preloader__sector"></div>
        </div>
      </div>
    )
  }
}

export default ComponentSpinner
