const stellarinitialState = {
    wallets: [],
    getbeneficiaries: {},
    liveprice: {},
    currentwallet: {},
    allasets: {}
}

const stellar = (state = stellarinitialState, action) => {
    switch (action.type) {
        case "AllASSETS":
            return { ...state, allasets: action.data }
        case "GETBENEFICIARIES":
            return { ...state, getbeneficiaries: action.data }
        case "BENEFICIARIES":
            return { ...state, beneficiaries: action.data }
        case "LIVEPRICE":
            return { ...state, liveprice: action.data }
        case "WALLETS":
            return {
                ...state,
                wallets: action.data,
                currentwallet: action.currentwallet,
                allasets: action.allasets
            }
        case "CURRENT":
            return {
                ...state,
                currentwallet: action.currentwallet,
                allasets: action.allasets,
            }
        case "TRUSTLINE":
            return { ...state, addtrustline: action.data }
        case "CURRENCIES":
            return {
                ...state,
                fiatcurrencies: action.data.fiat,
                cryptocurrencies: action.data.crypto,
                allcurrencies: action.data.all
            }
        case "PRICE":
            return {
                ...state,
                xlmprice: action.data.prices,
                europrice: action.data.europrice
            }
        default:
            return state
    }
}

export default stellar