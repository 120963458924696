import React, { Suspense, lazy } from "react"
import { Router, Switch, Route, Redirect } from "react-router-dom"
import { connect, useSelector } from "react-redux";
import { ToastContainer } from 'react-toastify';
import { history } from "@src/resource/history"
import Spinner from "./Loading-spinner"
import { ContextLayout } from "../layouts/Layout"
import '@assets/css/webscrollbar.css';


// Components
const Dashboard = lazy(() => import('./components/main/dashboard'));

// const Aconversation  = lazy(() => import('./components/main/currencycloud/aconversation/aconversation'));
// const Beneficiaries  = lazy(() => import('./components/main/currencycloud/beneficiaries/beneficiaries'));
// const AddBeneficiaries  = lazy(() => import('./components/main/currencycloud/beneficiaries/addbeneficiaries'));
// const Convert  = lazy(() => import('./components/main/currencycloud/convert/convert'));
// const Onboarding  = lazy(() => import('./components/main/currencycloud/onboarding/onboarding'));
// const Pay  = lazy(() => import('./components/main/currencycloud/pay/pay'));
// const Search  = lazy(() => import('./components/main/currencycloud/search/search'));
// const Transfers  = lazy(() => import('./components/main/currencycloud/transfers/transfers'));
// const Transactions  = lazy(() => import('./components/main/currencycloud/transactions/transactions'));
// const Balances  = lazy(() => import('./components/main/currencycloud/balances/balances'));

// Admin
const Users = lazy(() => import('./components/main/admin/users'));
const ADeposit = lazy(() => import('./components/main/admin/deposit'));
const ADWithdraw = lazy(() => import('./components/main/admin/withdraw'));
const Adminlogin = lazy(() => import('./components/main/admin/auth'));
const ABuy = lazy(() => import('./components/main/admin/buy'));
const ASell = lazy(() => import('./components/main/admin/sell'));
const AdminWallets = lazy(() => import('./components/main/admin/wallets'));
const AdminWalletItem = lazy(() => import('./components/main/admin/wallets/walletItem'));

const Buy = lazy(() => import('./components/main/stellar/buy/buy'));
const Sell = lazy(() => import('./components/main/stellar/sell/sell'));
const Exchange = lazy(() => import('./components/main/stellar/exchange/exchange'));
const Deposit = lazy(() => import('./components/main/stellar/deposit/deposit'));
const Transfer = lazy(() => import('./components/main/stellar/transfer/transfer'));
const Trustline = lazy(() => import('./components/main/stellar/trustline'));
const Withdraw = lazy(() => import('./components/main/stellar/withdraw/withdraw'));
const UserWallet = lazy(() => import('./components/main/stellar/wallet/user'));
const TrustlineWallet = lazy(() => import('./components/main/stellar/wallet/trustline'));
const TrustlineWalletDetail = lazy(() => import('./components/main/stellar/wallet/trustline/trustlineDetail'));
const KYCpersonal = lazy(() => import('./components/main/stellar/kyc/personal'));
const KYCorganization = lazy(() => import('./components/main/stellar/kyc/organization'));

const Company = lazy(() => import('./components/main/user/company'));
const Benefic = lazy(() => import('./components/main/beneficiaries/manage'));

const Signin = lazy(() => import('./components/auth/signin'));
const Signup = lazy(() => import('./components/auth/signup'));
const Forgot = lazy(() => import('./components/auth/forgot'));
const Reset = lazy(() => import('./components/auth/reset'));
const Error = lazy(() => import('./content/404'));
const EmailChildren = lazy(() => import('./content/emailChildren'));

// Set Layout and Component Using App Route
const RouteConfig = ({ component: Component, fullLayout, adminLayout, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            return (
                <ContextLayout.Consumer>
                    {context => {
                        let LayoutTag =
                            fullLayout === true
                                ? context.fullLayout
                                : adminLayout === true
                                    ? context.AdminLayout
                                    : context.VerticalLayout
                        return (
                            <LayoutTag {...props} permission={props.user}>
                                <Suspense fallback={<Spinner />}>
                                    <Component {...props} />
                                </Suspense>
                            </LayoutTag>
                        )
                    }}
                </ContextLayout.Consumer>
            )
        }}
    />
)

const mapStateToProps = state => {
    return {
        user: 1
    }
}

const verifyRoute = (path, pathname) => {
    if (path === pathname) {
        return true;
    } else {
        let pathSlashCount = path.split('/').length - 1;
        var pathnameSlashCount = pathname.split('/').length - 1;
        if (pathSlashCount === pathnameSlashCount && path.indexOf('/:') > 0) {
            if (path.slice(0, path.indexOf('/:')) === pathname.slice(0, path.indexOf('/:'))) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
}

const RequireAuth = (data) => {
    const isAuthorized = useSelector((state) => state.auth.isAuth);
    if (!isAuthorized && history.location.pathname !== "/signin") {
        return <Redirect to={"/signin"} />;
    }
    for (var i in data.children) {
        if (data.children[i].props.path && verifyRoute(data.children[i].props.path, data.location.pathname)) {
            return data.children.slice(0, data.children.length - 1);
        }
    }
    return data.children.slice(data.children.length - 1, data.children.length);
};


const AppRoute = connect(mapStateToProps)(RouteConfig)

class AppRouter extends React.Component {
    render() {
        return (
            <>
                <Router history={history}>
                    <Switch>
                        <AppRoute
                            path="/signout"
                            exact
                            component={() => <Redirect to="/signin" />}
                        />
                        <AppRoute path="/signin" component={Signin} fullLayout />
                        <AppRoute path="/signup" component={Signup} fullLayout />
                        <AppRoute path="/forgot" component={Forgot} fullLayout />
                        <AppRoute path="/resetpassword" component={Reset} fullLayout />
                        <AppRoute path="/resetpassword/:token" component={Reset} fullLayout />
                        <AppRoute path="/emailconfirm/:data" children={<EmailChildren />} fullLayout />

                        <RequireAuth>

                            <AppRoute
                                path="/admin"
                                exact
                                component={() => <Redirect to="/admin/dashboard" />}
                            />

                            <AppRoute
                                path="/admin/signout"
                                exact
                                component={() => <Redirect to="/admin/login" />}
                            />

                            <AppRoute path="/admin/users" component={Users} adminLayout />
                            <AppRoute path="/admin/deposit" component={ADeposit} adminLayout />
                            <AppRoute path="/admin/withdraw" component={ADWithdraw} adminLayout />
                            <AppRoute path="/admin/buy" component={ABuy} adminLayout />
                            <AppRoute path="/admin/sell" component={ASell} adminLayout />
                            <AppRoute path="/admin/dashboard" exact component={AdminWallets} adminLayout />
                            <AppRoute path="/admin/dashboard/:walletId" component={AdminWalletItem} adminLayout />
                            <AppRoute path="/admin/login" component={Adminlogin} fullLayout />

                            <AppRoute exact path="/" component={Dashboard} />

                            <AppRoute path="/company" component={Company} />

                            <AppRoute path="/hellenium/transfer" component={Transfer} />
                            <AppRoute path="/hellenium/trustline" component={Trustline} />

                            <AppRoute path="/hellenium/crypto/buy" component={Buy} />
                            <AppRoute path="/hellenium/crypto/sell" component={Sell} />

                            <AppRoute path="/hellenium/fiat/deposit" component={Deposit} />
                            <AppRoute path="/hellenium/fiat/withdraw" component={Withdraw} />
                            <AppRoute path="/hellenium/fiat/exchange" component={Exchange} />

                            <AppRoute path="/beneficiaries/manage" component={Benefic} />
                            <AppRoute path="/wallet/user" component={UserWallet} />
                            <AppRoute path="/wallet/trustline" exact component={TrustlineWallet} />
                            <AppRoute path="/wallet/trustline/:publicKey" component={TrustlineWalletDetail} />

                            <AppRoute path="/kyc/personal" component={KYCpersonal} />
                            <AppRoute path="/kyc/organization" component={KYCorganization} />

                            <AppRoute component={Error} fullLayout />
                        </RequireAuth>
                    </Switch>
                </Router>
                <ToastContainer />
            </>
        )
    }
}

const mapStateToPropss = (state) => ({
    isAuth: state.auth.isAuth,
})
export default connect(mapStateToPropss, {})(AppRouter);