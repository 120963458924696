import Cookies from 'universal-cookie';

var config = {
    // server_url : 'http://localhost:1209/api/',
    // horizon_url : 'https://horizon-testnet.stellar.org',
    // client_url : 'http://localhost:5000',
    // toml_url : 'localhost:1209',
    // dev: true,
    
    server_url : 'https://e-wallet.hellenium.com/api/',
    horizon_url : 'https://horizon.hellenium.com',
    client_url : 'https://e-wallet.hellenium.com',
    toml_url : 'e-wallet.hellenium.com',
    dev: false,
    
    login_id : 'stigerpanda@gmail.com',
    currency_base_euro : 'https://api.exchangeratesapi.io/latest',
    api_key : '3594d1f698970f8a2f9748985f95ddae8c5a79d004c78afd3385b5c0d3ae3314',
    JWT_TOKEN : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJyb2xlIjoiYWRtaW4iLCJleHAiOjE1OTM4MjgyMjJ9.hPPMV3EMqtq5SbNxd2cf9fvoQuvRKc_drP9ndo_FZfw',
    
    // paypalClient : 'AYfJ9Wyi-5AhTogPKOFDPsoRcb-j9OV0qT9Q7FUpTK-7FO8juBU7MupyBf0IC-QugAmzCe4Y05ca3Pt0',
    // paypalSecret : 'EF1ojHsSA4InU41kKfqa-LI9b7T7K2joNx8yhxvaQApR7aGiIp3G1FILoO3BH7o2OJmJNQ70tZprsIYf',
    // paypalClient : 'AetegxCjqzn7rQtsYMJXsKTlQ4an0kgYUaRCIejm6WAHYS_yaM56fZnIxikDVRXWMkIu-FpkuMq3AdWC',
    // paypalSecret : 'EIMhJKU-aUdaOZ-Gt54Wx3_imvwHt6EZIRRZrIE2UrLD1r5DqC_nVmjAoaWg3IaRcWI45pE0OMyz-nC8',
    // passbase_api : 'qsX85bVS38MhVcU7rcB7mLckj8exq1hxpHh4nhdnIIZ9H3i47Y2NgUCGYK1DGCym',


    distribution: "GAZFBRU46VMU53EPHBITE4E4YMEGEOV6TAWO5HIBQZL7CAEPRTCP5Q56",
    
    passbase_api : 'N3pgo6TAcnhKle08momVozUEQGXOCFlON98TE4ylnlxR1130CGo2c40l0bnkmFmN',
    revolut_api : '0oI1UTdwku4ZV-VY8qqiYM6H-BBwdtALJZfscf70J3WjLYQvKqO0MwF3LPNgMWGN',
    request_url: 'https://devapi.currencycloud.com/v2',
    auth_token: "6b63d39ded0fb201a293f524b87a5318",
    sitekey: "6LecnHYdAAAAAOdLPpPm4SkSJoTt3iDiDmFJbYR3",
    secretkey: "6LecnHYdAAAAAE5iboyp8rFL8a0Sq0tjQcIa",
    iban: "GB29 REVO 0099 6998 5203 04",
    country: "United Kingdom",
    name: "Sotiris",
    userinfo: "",
    admininfo: {},
    needVariable: {}
}

try {
    const cookies = new Cookies();
    config.userinfo = JSON.parse(atob(cookies.get('token')));
} catch (err) {
    config.userinfo = "";
}

export default config;
