import React from "react"
import '@assets/css/auth/login.css';
import '@assets/css/style.css';

const FullPageLayout = ({ children, ...rest }) => {
  return (
    <>
      {children}
    </>
  )
}
export default FullPageLayout
