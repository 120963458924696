import React from 'react';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner'

class Balances extends React.Component {
    render () {
        return (
            <div className="subheader py-2 py-lg-4 subheader-solid" id="kt_subheader">
                <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-2">
                        {
                            this.props.allasets ?
                            this.props.allasets['stellar_balance'] !== 0 && this.props.allasets['stellar_balance'] !== undefined ? 
                            this.props.allasets['stellar_balance'].map((row, index) => {
                                return <span className="d-flex align-items-center" key={`${index}-balance`}>
                                            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 ml-4 mr-4 bg-gray-200"></div>
                                            <span className="text-muted font-weight-bold mr-4 mb-2">
                                                {
                                                    row.asset_code ? row.asset_code : "XLM"
                                                }
                                            </span>
                                            <h5>
                                                {
                                                    row.asset_code !== "msc1" && row.asset_code !== undefined ? parseFloat(row.balance).toFixed(2) : row.balance
                                                }
                                            </h5>
                                        </span>
                            }): "" : <Spinner animation="grow" variant="primary" />
                        }
                        {/* <button className="btn btn-primary ml-3">
                            <NavLink className="menu-link" to="/hellenium/transfer">
                                <i className="menu-bullet menu-bullet-dot">
                                    <span/>
                                </i>
                                <span className="menu-text text-white">Create a payment</span>
                            </NavLink>
                        </button> */}
                        <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 ml-4 bg-gray-200"></div>
                    </div>
                </div>
            </div>
        )
    }
}

function done(state) {
    return {
        allasets: state.stellar.allasets
    }
}

export default connect(done, {  })(Balances);

