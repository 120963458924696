const socketinitialState = {
  socket: {},
  socketstatus: null
}

const socket = (state = socketinitialState , action) => {
  switch (action.type) {
    case "SOCKET":
      return { ...state, socket: action.data }
    case "SOCKET_STATUS":
      return { ...state, socketstatus: action.data }
    default:
      return state
  }
}
  
export default socket