import React, { useState } from "react"
// contents
import Leftbar from '../resource/content/leftbar';
import Balances from '../resource/content/balances';
import Footer from '../resource/content/footer';
import Header from '../resource/content/header';
import User from '../resource/content/user';
import { ScrollTop } from './assets/components/extras/ScrollTop';
import { LayoutInit } from "./assets/components/LayoutInit";
import 'react-toastify/dist/ReactToastify.css';

// Css
import '@assets/css/plugins.css';
import '@assets/css/style.css';
import '@assets/css/dark.css';
import '@assets/css/light.css';
import '@assets/css/webscrollbar.css';

const Verticallayout = ({ children, ...rest }) => {
    const [status, setStatus] = useState(0);
    const setS = (flag) => {
        setStatus(flag)
    }
    return (
        <>
            <div className="d-flex flex-column flex-root">
                <div className="d-flex flex-row flex-column-fluid page">
                    <Leftbar />
                    <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                        <Header parent={setS} status={status} />
                        <div className="content d-flex flex-column flex-column-fluid pt-0" id="kt_content">
                            <Balances />
                            {children}
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
            <User status={status} parent={setS} />
            <ScrollTop />
            <LayoutInit />
        </>
    )
}
export default Verticallayout
