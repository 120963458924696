import { toast } from 'react-toastify';
// toast.configure();

export const customToast = (content, state, autoClose = 2000) => {
    if (state === 'success') {
        toast.success(content, {
            position: "top-right",
            autoClose: autoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } else if (state === 'warning') {
        toast.warning(content, {
            position: "top-right",
            autoClose: autoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } else if (state === 'error') {
        toast.error(content, {
            position: "top-right",
            autoClose: autoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } else if (state === 'info') {
        toast.info(content, {
            position: "top-right",
            autoClose: autoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    return dispatch => {
        dispatch({ type: 'ERROR', data: 'fixed' });
    }
}