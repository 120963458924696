import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./resource/App";
import { Layout } from "./layouts/Layout";
import configStore from './redux/stores';
import Spinner from "./resource/Loading-spinner";
import { checkSession } from "@action/session";

const renderApp = preloadedState => {
    const store = configStore(preloadedState);
    ReactDOM.render(
        <Provider store={store}>
            <Suspense fallback={<Spinner />}>
                <Layout>
                    <App />
                </Layout>
            </Suspense>
        </Provider>,
        document.getElementById("root")
    );
}

(async () => renderApp(await checkSession()))();