import { AXIOS_REQUEST } from '@action/auth';
import { setSession, removeSession } from '../session';
import { customToast } from '@src/resource/toast';

export const signUp = (data) => {
    return async (dispatch) => {
        var response = await AXIOS_REQUEST('user/register', data);
        if (response.status) {
            customToast('Verify link sent to your email', 'success')
        } else {
            customToast(response.message, 'error');
        }
    }
}

export const login = async (data) => {
    var response = await AXIOS_REQUEST("user/login", data);
    if (response.status) {
        setSession(response.token);
        window.location.assign("/");
    } else {
        customToast(response.message, 'error');
    }
}

export const signOut = () => {
    return async (dispatch) => {
        await AXIOS_REQUEST("user/logout");
        removeSession();
        dispatch({ type: "SIGNOUT", data: null });
        window.location.assign("/signin");
    }
}

// Socket connection
export const socketconnect = (sendertype) => {
    // return async (dispatch) => {
    //     if (cookies.get('socket') === undefined) {
    //         let socket = null;
    //         socket = socketIOClient(config.server_url);
    //         socket.emit('getid', { userid: config.userinfo, type: sendertype });
    //         socket.on('sendid', (id) => {
    //             if (config.dev === true) {
    //                 cookies.set('socket', id, { path: '/', domain: 'localhost' });
    //             } else {
    //                 cookies.set('socket', id, { path: '/', domain: 'hellenium.com' });
    //             }
    //         })
    //         dispatch({
    //             type: "SOCKET",
    //             data: socket
    //         })
    //     } else {
    //         let result = await axios.post(`${config.server_url}/socket/check`, { data: { socketID: cookies.get('socket') } });
    //         if (result.data.status === true) {
    //             dispatch({
    //                 type: "SOCKET_STATUS",
    //                 data: true
    //             })
    //         } else {
    //             let socket = null;
    //             socket = socketIOClient(config.server_url);
    //             socket.emit('getid', { userid: config.userinfo, type: sendertype });
    //             socket.on('sendid', (id) => {
    //                 if (config.dev === true) {
    //                     cookies.set('socket', id, { path: '/', domain: 'localhost' });
    //                 } else {
    //                     cookies.set('socket', id, { path: '/', domain: 'hellenium.com' });
    //                 }
    //             })
    //             dispatch({
    //                 type: "SOCKET",
    //                 data: socket
    //             })
    //         }
    //     }
    // }
}

export const getUserInfo = () => {
    return async (dispatch) => {
        var response = await AXIOS_REQUEST("user/getUserInfo");
        dispatch({ type: "USERINFO", data: response.data });
    }
}

export const forgotpassword = (email) => {
    return async (dispatch) => {
        var response = await AXIOS_REQUEST("user/forgotPassword", { email: email });
        if (response.status) {
            dispatch({ type: "FORGOTPASS", data: response.status });
        } else {
            customToast(response.message, 'error');
        }
    }
}

export const resetpass = (email) => {
    return async (dispatch) => {
        var response = await AXIOS_REQUEST("user/resetPassword", { email: email });
        if (response.status) {
            dispatch({ type: "FORGOTPASS", data: response.status });
        } else {
            customToast(response.message, 'error');
        }
    }
}

export const checktoken = () => {
    return async (dispatch) => {
        var response = await AXIOS_REQUEST("user/checkToken");
        if (response.status) {
            dispatch({ type: "FORGOTPASS", data: response.status });
        } else {
            customToast(response.message, 'error');
        }
    }
}