const initState = {
    activeWallet: {},
    allWallets: [],
    allTrans: []
}

const WalletReducer = (state = initState, action) => {
    switch (action.type) {
        case "ACTIVEWALLET":
            return { ...state, activeWallet: action.data };
        case "ALLWALLETS":
            return { ...state, allWallets: action.data };
        case "ALLTRANS":
            return { ...state, allTrans: action.data };

        default:
            return state;
    }
}

export default WalletReducer