import { AUTH_DATA, USER_INFO } from "@type";

const initState = {
    isAuth: false,
    isLoading: false,
    userInfo: {},
    walletInfo: {}
}

const auth = (state = initState, action) => {
    switch (action.type) {
        case AUTH_DATA:
            return { ...state, isAuth: action.data };
        case USER_INFO:
            return { ...state, userInfo: action.data };
        default:
            return state
    }
}

export default auth