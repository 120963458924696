/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { checkIsActive } from "../../layouts/_helpers";
import Layers from '@assets/media/svg/Layers.svg';
import Box2 from '@assets/media/svg/Box2.svg';


const AsideMenuList = () => {
    var userInfo = useSelector((state) => state.auth.userInfo);

    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };

    return (
        <ul className={`menu-nav`} >
            <li className={`menu-item ${getMenuItemActive("/", false)}`}>
                <NavLink className="menu-link" to="/">
                    <span className="svg-icon menu-icon">
                        <SVG src={Layers} />
                    </span>
                    <span className="menu-text">Dashboard</span>
                </NavLink>
            </li>
            <li className="menu-section  menu-item-open">
                <h4 className="menu-text">main</h4>
                <i className="menu-icon flaticon-more-v2"></i>
            </li>
            <li className={`menu-item menu-item-submenu ${getMenuItemActive("/kyc", true)}`} data-menu-toggle="hover">
                <NavLink className="menu-link menu-toggle" to="/kyc">
                    <span className="svg-icon menu-icon">
                        <SVG src={Box2} />
                    </span>
                    <span className="menu-text">Verify identity</span>
                    <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav menu-item-submenu menu-item-open">
                        <li className={`menu-item ${getMenuItemActive("/kyc/personal")}`}>
                            <NavLink className="menu-link" to="/kyc/personal">
                                <i className="menu-bullet menu-bullet-dot">
                                    <span />
                                </i>
                                <span className="menu-text">Personal</span>
                            </NavLink>
                        </li>
                        <li className={`menu-item ${getMenuItemActive("/kyc/organization")}`}>
                            <NavLink className="menu-link" to="/kyc/organization">
                                <i className="menu-bullet menu-bullet-dot">
                                    <span />
                                </i>
                                <span className="menu-text">Business</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </li>
            <li className={`menu-item menu-item-submenu ${getMenuItemActive("/wallet", true)}`} data-menu-toggle="hover">
                <NavLink className="menu-link menu-toggle" to="/wallet">
                    <span className="svg-icon menu-icon">
                        <SVG src={Layers} />
                    </span>
                    <span className="menu-text">Wallet</span>
                    <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav menu-item-submenu menu-item-open">
                        <li className={`menu-item ${getMenuItemActive("/wallet/user")}`}>
                            <NavLink className="menu-link" to="/wallet/user">
                                <i className="menu-bullet menu-bullet-dot">
                                    <span />
                                </i>
                                <span className="menu-text">My wallet</span>
                            </NavLink>
                        </li>
                        {
                            userInfo.role === 3 && 
                                <li className={`menu-item ${getMenuItemActive("/wallet/trustline")}`}>
                                    <NavLink className="menu-link" to="/wallet/trustline">
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span />
                                        </i>
                                        <span className="menu-text">Trustline Wallet</span>
                                    </NavLink>
                                </li>
                        }
                    </ul>
                </div>
            </li>
            <li className={`menu-item ${getMenuItemActive("/beneficiaries/manage", false)}`}>
                <NavLink className="menu-link" to="/beneficiaries/manage">
                    <span className="svg-icon menu-icon">
                        <SVG src={Layers} />
                    </span>
                    <span className="menu-text">Beneficiaries</span>
                </NavLink>
            </li>
            <li className={`menu-item menu-item-submenu ${getMenuItemActive("/hellenium", true)}`} data-menu-toggle="hover">
                <NavLink className="menu-link menu-toggle" to="/hellenium">
                    <span className="svg-icon menu-icon">
                        <SVG src={Box2} />
                    </span>
                    <span className="menu-text">Hellenium</span>
                    <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav menu-item-submenu menu-item-open">
                        <li className={`menu-item ${getMenuItemActive("/hellenium/trustline")}`}>
                            <NavLink className="menu-link" to="/hellenium/trustline">
                                <i className="menu-bullet menu-bullet-dot">
                                    <span />
                                </i>
                                <span className="menu-text">Trustline</span>
                            </NavLink>
                        </li>
                        <li className={`menu-item ${getMenuItemActive("/hellenium/transfer")}`}>
                            <NavLink className="menu-link" to="/hellenium/transfer">
                                <i className="menu-bullet menu-bullet-dot">
                                    <span />
                                </i>
                                <span className="menu-text">Create a payment</span>
                            </NavLink>
                        </li>
                        <li className={`menu-item menu-item-submenu ${getMenuItemActive("/hellenium/crypto", true)}`} data-menu-toggle="hover">
                            <NavLink className="menu-link menu-toggle" to="/hellenium/crypto">
                                <span className="svg-icon menu-icon">
                                    <SVG src={Box2} />
                                </span>
                                <span className="menu-text">Crypto</span>
                                <i className="menu-arrow" />
                            </NavLink>
                            <div className="menu-submenu ">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav menu-item-submenu menu-item-open">
                                    <li className={`menu-item ${getMenuItemActive("/hellenium/crypto/buy")}`}>
                                        <NavLink className="menu-link" to="/hellenium/crypto/buy">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                            </i>
                                            <span className="menu-text">Buy</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive("/hellenium/crypto/sell")}`}>
                                        <NavLink className="menu-link" to="/hellenium/crypto/sell">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                            </i>
                                            <span className="menu-text">Sell</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className={`menu-item menu-item-submenu ${getMenuItemActive("/hellenium/fiat", true)}`} data-menu-toggle="hover">
                            <NavLink className="menu-link menu-toggle" to="/hellenium/fiat">
                                <span className="svg-icon menu-icon">
                                    <SVG src={Box2} />
                                </span>
                                <span className="menu-text">Fiat</span>
                                <i className="menu-arrow" />
                            </NavLink>
                            <div className="menu-submenu ">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav menu-item-submenu menu-item-open">
                                    <li className={`menu-item ${getMenuItemActive("/hellenium/fiat/deposit")}`}>
                                        <NavLink className="menu-link" to="/hellenium/fiat/deposit">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                            </i>
                                            <span className="menu-text">Deposit</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive("/hellenium/fiat/exchange")}`}>
                                        <NavLink className="menu-link" to="/hellenium/fiat/exchange">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                            </i>
                                            <span className="menu-text">Exchange</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive("/hellenium/fiat/withdraw")}`}>
                                        <NavLink className="menu-link" to="/hellenium/fiat/withdraw">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                            </i>
                                            <span className="menu-text">Withdraw</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    );
}

export default AsideMenuList;