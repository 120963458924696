import React from "react"
// contents
import Header from '../resource/components/main/admin/content/header';
// import Balances from '../resource/content/balances';
// import Footer from '../resource/content/footer';
// import Header from '../resource/content/header';
// import User from '../resource/content/user';
import { ScrollTop } from './assets/components/extras/ScrollTop';
import { LayoutInit } from "./assets/components/LayoutInit";
import 'react-toastify/dist/ReactToastify.css';

// Css
import '@assets/css/plugins.css';
import '@assets/css/style.css';
import '@assets/css/dark.css';
import '@assets/css/light.css';
import '@assets/css/webscrollbar.css';

const Adminlayout = ({ children, ...rest }) => {
    return (
        <>
            <div className="d-flex flex-column flex-root">
                <div className="d-flex flex-row flex-column-fluid page">
                    <Header />
                    <div className="d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        {children}
                    </div>
                </div>
            </div>
            <ScrollTop />
            <LayoutInit />
        </>
    )
}
export default Adminlayout
