import { AXIOS_REQUEST } from '@action/auth';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const getSession = () => {
    return cookies.get('token');
}

export const setSession = (token) => {
    return cookies.set('token', token);
}

export const removeSession = () => {
    cookies.remove('token');
}

export const checkSession = async () => {
    try {
        var token = getSession();
        if (token) {
            var response = await AXIOS_REQUEST("session/checkSession", { token: token });
            if (response.status) {
                return {
                    auth: {
                        isAuth: true,
                        isLoading: false,
                        userInfo: response.userInfo,
                        walletInfo: response.walletInfo
                    }
                };
            } else {
                removeSession();
                return { auth: { isAuth: false } };
            }
        } else {
            return { auth: { isAuth: false } };
        }
    } catch (error) {
        return { auth: { isAuth: false } };
    }
}

// if (window.location.href.indexOf('admin') === -1) {
//     if (!cookies.get('token')) {
//         if (history.location.pathname !== "/signin" && history.location.pathname !== "/") {
//             customToast("You have to signin.", "error");
//             history.push('/signin');
//         } else if (history.location.pathname === "/") {
//             history.push('/signin');
//         }
//     } else {
//         var token = getSession();
//         axios.post(config.server_url + 'session/check', { token: token })
//             .then(res => {
//                 if (res.data.status === false) {
//                     customToast("Session expired.", "info");
//                     if (config.dev === true) {
//                         cookies.remove('token', { path: '/', domain: "localhost" });
//                     } else {
//                         cookies.remove('token', { path: '/', domain: "hellenium.com" });
//                     }
//                     history.push('/signout');
//                 }
//             })
//     }
// } else {
//     if (window.location.href.indexOf('/admin/login') === -1) {
//         let adtoken = cookies.get('adtoken');
//         if (adtoken === null || adtoken === undefined) {
//             history.push('/admin/login');
//         } else {
//             let adtodata = atob(adtoken);
//             axios.post(config.server_url + 'admin/check', { data: adtodata })
//                 .then(res => {
//                     if (res.data.status === false) {
//                         customToast("Session expired.", "info");
//                         if (config.dev === true) {
//                             cookies.remove('adtoken', { path: '/', domain: "localhost" });
//                         } else {
//                             cookies.remove('adtoken', { path: '/', domain: "hellenium.com" });
//                         }
//                         history.push('/admin/signout');
//                     }
//                 })
//                 .catch(err => {
//                     history.push('/admin/login');
//                 })
//         }
//     }
// }