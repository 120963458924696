const admininitialState = {
    adminfo: "",
    depositsdata: [],
    withdrawdata: [],
    buydata: [],
    selldata: [],
    usersdata : [],
    walletdata : [],
    walletprice : [],
    realBalance : [],
    updateBalanceStatus: false,
}

const admin = (state = admininitialState , action) => {
    switch (action.type) {
        case "ADMININFO":
            return { ...state, adminfo: action.data }
        case "DEPOSTREQUESTS":
            return { ...state, depositsdata: action.data }
        case "WITHDRAWREQUESTS":
            return { ...state, withdrawdata: action.data }
        case "BUYREQUESTS":
            return { ...state, buydata: action.data }
        case "SELLREQUESTS":
            return { ...state, selldata: action.data }
        case "USERS":
            return { ...state, usersdata: action.data }
        case "WALLETS":
            return { ...state, walletdata: action.data }
        case "WALLETPRICE":
            return { ...state, walletprice: action.balance, realBalance: action.real, updateBalanceStatus: false }
        case "SENDREWARDS":
            return { ...state, updateBalanceStatus: action.data }
        default:
            return state
    }
}
    
export default admin