const userinitialState = {
    userInfo: {},
    search: {},
    kyc: false,
    kyb: false,
    passbase: null,
    tdata: []
}

const UserReducer = (state = userinitialState, action) => {
    switch (action.type) {
        case "USERINFO":
            return { ...state, userInfo: action.data }
        case "SEARCH":
            return { ...state, search: action.data }
        case "CONFIRM":
            return { ...state, confirm: action.data }
        case "KYC":
            return { ...state, kyc: action.data }
        case "KYB":
            return { ...state, kyb: action.data }
        case "PASSBASE":
            return { ...state, passbase: action.data }
        case "TRANDATA":
            return { ...state, tdata: action.data }
        case "FORGOTPASS":
            return { ...state, forgotpass: action.data }
        case "TOKENSTATUS":
            return { ...state, tokenstatus: action.data }
        case "RESETPASS":
            return { ...state, resetpass: action.data }
        default:
            return state
    }
}

export default UserReducer