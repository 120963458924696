import React from 'react';
import { Link } from 'react-router-dom';
import SVG from "react-inlinesvg";
import { connect } from 'react-redux';
import userImage from '@assets/media/users/300_21.jpg';
import Mail from '@assets/media/svg/Mail-notification.svg';
import Box from '@assets/media/svg/Box.svg';
import { FiEdit } from 'react-icons/fi';
// import { SiStellar } from 'react-icons/si';
// import { ImAddressBook } from 'react-icons/im';
// import { GiMoneyStack } from 'react-icons/gi';
// import { RiLuggageDepositLine } from 'react-icons/ri';
// import { GoRepoPull } from 'react-icons/go';

class User extends React.Component {
    
    constructor() {
        super()
        this.state = {
            firstname: '',
            lastname: '',
            email: '',
            public_key: '',
            file: null
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.prosp !== nextProps) {
            if(nextProps.userdata) {
                this.setState({
                    email: nextProps.userdata.email,
                    firstname: nextProps.userdata.firstname,
                    lastname: nextProps.userdata.lastname,
                    public_key: nextProps.userdata.public_key
                })
            }
        }
    }

    render () {
        return (
            <div id="kt_quick_user" className={this.props.status === 0 ? "offcanvas offcanvas-right p-10" : "offcanvas offcanvas-right p-10 offcanvas-on"}>
                <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                    <h3 className="font-weight-bold m-0">User Profile</h3>
                    <Link to="#" className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={() => this.props.parent(0)}>
                        <i className="ki ki-close icon-xs text-muted"></i>
                    </Link>
                </div>
                <div className="offcanvas-content pr-5 mr-n5">
                    <div className="d-flex align-items-center mt-5">
                        <div className="symbol symbol-100 mr-5">
                            <div className="symbol-label" style={{ backgroundImage:`url(${this.state.file? URL.createObjectURL(this.state.file) : userImage})` }}></div>
                            <input type="file" className="d-none changeImage" onChange={e => this.setState({file: e.target.files[0]})} />
                            <i className="symbol-badge bg-success"></i>
                        </div>
                        <div className="d-flex flex-column">
                            <small className="text-muted font-size-sm ml-2">Please click to edit profile for KYC</small>
                            <Link to="/profile" className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"> {this.state.firstname + " " + this.state.lastname} </Link>
                            <div className="navi mt-2">
                                <Link to="#" className="navi-item">
                                    <span className="navi-link p-0 pb-2">
                                        <span className="navi-icon mr-1">
                                            <span className="svg-icon svg-icon-lg svg-icon-primary">
                                                <SVG src={Mail} />
                                            </span>
                                        </span>
                                        <span className="navi-text text-muted text-hover-primary">{this.state.email}</span>
                                    </span>
                                </Link>
                            </div>
                            <div className="navi mt-2">
                                <Link to="#" className="navi-item">
                                    <span className="navi-link p-0 pb-2">
                                        <span className="navi-icon mr-1">
                                            <span title="Copy public key" className="svg-icon svg-icon-lg svg-icon-primary">
                                                <SVG src={Box} />
                                            </span>
                                        </span>
                                        <span className="navi-text text-muted text-hover-primary">{this.state.public_key}</span>
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <button className="btn btn-primary" onClick={() => document.querySelector(".changeImage").click()}><FiEdit /> Change</button>
                    <div className="separator separator-dashed mt-8 mb-5"></div>
                </div>
            </div>
        )
    }
}

function done(state) {
    return {
        userdata: state.user.userdata
    }
}

export default connect(done, {})(User);
