import React from 'react';
import { connect } from 'react-redux';
import { history } from '@src/resource/history';
import { isMobile } from "react-device-detect";
// import Cookies from 'universal-cookie';

// import config from '@config';
import { signOut } from '@action/auth/user';
import { customToast } from '@src/resource/toast';

import { GoSignOut } from 'react-icons/go';
import { ImOffice } from 'react-icons/im';
import { AiFillCopy } from 'react-icons/ai';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import "@assets/scss/simpleStyle.scss";

class Header extends React.Component {

    constructor() {
        super()
        this.state = {
            flag: false,
            arrow: 'right'
        }
    }

    UNSAFE_componentWillReceiveProps(prevProps, nextProps) {
        if (this.props !== nextProps && this.state.flag === false) {
            this.setState({ flag: true });
            // let socket = nextProps.socket;

            // try {
            //     socket.on('getDepositdone', (data) => {
            //         if (data === true) {
            //             customToast("Deposit done!", "success");
            //             this.props.getActiveWallet();
            //         } else {
            //             customToast("Deposit Failed!", "error");
            //         }
            //     })

            //     socket.on('getWithdrawdone', (data) => {
            //         if (data === true) {
            //             customToast("Withdrawal done! Please confirm your bank", "success");
            //         } else {
            //             customToast("Withdrawal Failed!", "error");
            //         }
            //         this.props.getActiveWallet();
            //     })

            //     socket.on('getBuydone', (data) => {
            //         if (data === true) {
            //             customToast("Buy request done!", "success");
            //         } else {
            //             customToast("Buy request Failed!", "error");
            //         }
            //         this.props.getActiveWallet();
            //     })

            //     socket.on('mCreateapayment', (data) => {
            //         customToast(`${data.sender} sent ${data.amount} ${data.asset} to you!`, "success");
            //         this.props.getActiveWallet();
            //     })
            // } catch {
            //     const cookies = new Cookies();
            //     if (config.dev === true) {
            //         cookies.remove('socket', { path: '/', domain: "localhost" });
            //     } else {
            //         cookies.remove('socket', { path: '/', domain: "hellenium.com" });
            //     }
            //     window.location.href = config.client_url;
            // }
        }
    }

    signout = () => {
        this.props.signOut();
        history.push('/signin');
    }

    gotohome = () => {
        history.push('/');
    }

    gotoaddcompany = () => {
        history.push('/company');
    }

    copyText() {
        var copyinput = document.createElement("input");
        copyinput.setAttribute("id", "copytext");
        copyinput.setAttribute("value", this.props.activeWallet.publicKey);
        document.body.append(copyinput);
        var copyText = document.getElementById("copytext");
        copyText.select();
        document.execCommand("copy");
        copyinput.remove();

        customToast("Address copied successfully!", "success");
    }

    rightleftbar() {
        document.getElementsByClassName('aside-left')[0].style.left = '0px';
        this.setState({ arrow: 'left' })
    }

    leftleftbar() {
        document.getElementsByClassName('aside-left')[0].style.left = '-295px';
        this.setState({ arrow: 'right' })
    }

    render() {
        const { userInfo, activeWallet } = this.props;

        return (
            <div id="kt_header" className="header header-fixed">
                <div className="container-fluid d-flex align-items-stretch justify-content-between">
                    <div className="header-menu-wrapper header-menu-wrapper-left">
                        <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>
                        <h5 className="font-weight-bold mr-4 mb-2 mt-8">
                            <span className="text-dark-50 font-weight-bolder mr-5">Wallet Address</span>
                            {activeWallet.publicKey ? activeWallet.publicKey.slice(0, 8) : ""}...
                            <summary className="text-dark-50 font-weight-bolder d-none d-md-inline ml-3 copyBtn" onClick={() => this.copyText()}>
                                <AiFillCopy className="text-primary" />
                            </summary>
                        </h5>
                    </div>
                    <div className="topbar pt-4 pb-4">
                        {
                            isMobile ? <div className="topbar-item pl-3">
                                <div className="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2" >
                                    <span className="symbol symbol-lg-35 symbol-25 symbol-light-primary">
                                        <span className="symbol-label font-size-h5 font-weight-bold">
                                            {this.state.arrow === 'right' ? <ArrowRightAltIcon onClick={() => this.rightleftbar()} /> : <KeyboardBackspaceIcon onClick={() => this.leftleftbar()} />}
                                        </span>
                                    </span>
                                </div>
                            </div> : ""
                        }

                        <div className="topbar-item pl-3">
                            <div className="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2" onClick={() => { this.props.status === 0 ? this.props.parent(1) : this.props.parent(0) }}>
                                <span className="symbol symbol-lg-35 symbol-25">
                                    <span className="symbol-label font-size-h5 font-weight-bold">
                                        <button className="btn btn-light-primary btn-bold align-items-center mr-3" onClick={() => this.gotoaddcompany()}>
                                            <ImOffice />
                                        </button>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="topbar-item pl-3">
                            <div className="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2" onClick={() => { this.props.status === 0 ? this.props.parent(1) : this.props.parent(0) }}>
                                <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
                                <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                                    {`${userInfo.firstName ? userInfo.firstName : ""} ${userInfo.lastName ? userInfo.lastName : ""}`}
                                </span>
                                <span className="symbol symbol-lg-35 symbol-25"></span>
                            </div>
                        </div>
                        <div className="topbar-item pl-3">
                            <div className="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2" onClick={() => { this.props.status === 0 ? this.props.parent(1) : this.props.parent(0) }}>
                                <span className="symbol symbol-lg-35 symbol-25">
                                    <span className="symbol-label font-size-h5 font-weight-bold">
                                        <button className="btn btn-light-primary btn-bold align-items-center mr-3" onClick={() => this.signout()}>
                                            <GoSignOut />
                                        </button>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function done(state) {
    return {
        socket: state.socket.socket,
        userInfo: state.auth.userInfo,
        activeWallet: state.auth.walletInfo
    }
}

export default connect(done, { signOut })(Header);