import axios from "axios";
import config from "@config";
import { customToast } from '@src/resource/toast';
import Cookies from "universal-cookie";
import { history } from "@src/resource/history";

export const adminsignin = (data) => {
    const cookies = new Cookies();
    return (dispatch) => {
        axios
            .post(config.server_url + "/admin/adminsignin", { data: data })
            .then((res) => {
                if (res.data.status === true) {
                    customToast("Success!", "success");
                    if (config.dev === true) {
                        cookies.set("adtoken", btoa(JSON.stringify(res.data.result)), {
                            path: "/",
                            domain: "localhost",
                        });
                    } else {
                        cookies.set("adtoken", btoa(JSON.stringify(res.data.result)), {
                            path: "/",
                            domain: "hellenium.com",
                        });
                    }
                    history.push("/admin/dashboard");
                } else {
                    customToast(res.data.result, "error");
                    return;
                }
                dispatch({
                    type: "ADMININFO",
                    data: res.data,
                });
            });
    };
};

export const depositsget = () => {
    return (dispatch) => {
        axios.post(config.server_url + "/admin/depositsget", {}).then((res) => {
            dispatch({
                type: "DEPOSTREQUESTS",
                data: res.data.result,
            });
        });
    };
};

export const depositschange = (data) => {
    return (dispatch) => {
        axios
            .post(config.server_url + "/admin/d_change", { data: data })
            .then((res) => {
                if (res.data.status === true) {
                    customToast("Success!", "success");
                } else {
                    customToast("Fail Something went wrong!", "error");
                }
                axios.post(config.server_url + "/admin/depositsget", {}).then((res) => {
                    dispatch({
                        type: "DEPOSTREQUESTS",
                        data: res.data.result,
                    });
                });
            });
    };
};

export const withdrawget = () => {
    return (dispatch) => {
        axios.post(config.server_url + "/admin/withdrawget", {}).then((res) => {
            dispatch({
                type: "WITHDRAWREQUESTS",
                data: res.data.result,
            });
        });
    };
};

export const withdrawchange = (data) => {
    return (dispatch) => {
        axios
            .post(config.server_url + "/admin/w_change", { data: data })
            .then((res) => {
                if (res.data.status === true) {
                    customToast("Success!", "success");
                } else {
                    customToast("Fail Something went wrong!", "error");
                }
                axios.post(config.server_url + "/admin/withdrawget", {}).then((res) => {
                    dispatch({
                        type: "WITHDRAWREQUESTS",
                        data: res.data.result,
                    });
                });
            });
    };
};

export const getusers = () => {
    return (dispatch) => {
        axios.post(config.server_url + "/admin/getusers", {}).then((res) => {
            dispatch({
                type: "USERS",
                data: res.data,
            });
        });
    };
};

export const DeleteUser = (partner_id) => {
    return (dispatch) => {
        axios
            .post(config.server_url + "/admin/deleteuser", { data: partner_id })
            .then((res) => {
                if (res.data.status === true) {
                    customToast("Success!", "success");
                    dispatch({
                        type: "USERS",
                        data: res.data,
                    });
                } else {
                    customToast(res.data.result, "error");
                    return;
                }
            });
    };
};

export const EditUser = (editeduser) => {
    return (dispatch) => {
        axios
            .post(config.server_url + "/admin/edituser", { data: editeduser })
            .then((res) => {
                if (res.data.status === true) {
                    customToast("Success!", "success");
                    dispatch({
                        type: "USERS",
                        data: res.data,
                    });
                } else {
                    customToast(res.data.result, "error");
                    return;
                }
            });
    };
};

export const buyget = () => {
    return (dispatch) => {
        axios.post(config.server_url + "/admin/buyget").then((res) => {
            dispatch({
                type: "BUYREQUESTS",
                data: res.data.result,
            });
        });
    };
};

export const buychange = (data) => {
    return (dispatch) => {
        axios
            .post(config.server_url + "/admin/b_change", { data: data })
            .then((res) => {
                if (res.data.status === true) {
                    customToast("Success!", "success");
                } else {
                    customToast("Fail Something went wrong!", "error");
                }
                axios.post(config.server_url + "/admin/buyget", {}).then((res) => {
                    dispatch({
                        type: "BUYREQUESTS",
                        data: res.data.result,
                    });
                });
            });
    };
};

export const deleteHistoryBuy = () => {
    return (dispatch) => {
        axios.post(config.server_url + "/admin/deleteHistoryBuy").then((res) => {
            dispatch({
                type: "BUYREQUESTS",
                data: res.data.result,
            });
        });
    };
};

export const sellget = () => {
    return (dispatch) => {
        axios.post(config.server_url + "/admin/sellget").then((res) => {
            dispatch({
                type: "SELLREQUESTS",
                data: res.data.result,
            });
        });
    };
};

// export const sellchange = (data) => {
//     return dispatch => {
//         axios.post(config.server_url + 'admin/s_change', {data: data})
//         .then(res => {
//             if(res.data.status === true) {
// customToast("Success!", "success");
//             } else {
// customToast("Fail Something went wrong!", "error");
//             }
//             axios.post(config.server_url + 'admin/sellget', {})
//             .then(res => {
//                 dispatch({
//                     type: "SELLREQUESTS",
//                     data: res.data
//                 })
//             })
//         })
//     }
// }

// export const deleteHistorySell = () => {
//     return dispatch => {
//         axios.post(config.server_url + 'admin/deleteHistorySell')
//         .then(res => {
//             dispatch({
//                 type: "BUYREQUESTS",
//                 data: res.data.result
//             })
//         })
//     }
// }

export const getWalletPrice = (walletId) => {
    return (dispatch) => {
        axios
            .post(config.server_url + "/admin/getWalletPrice", { walletId })
            .then((res) => {
                dispatch({
                    type: "WALLETPRICE",
                    balance: res.data.balance,
                    real: res.data.real,
                });
            });
    };
};

export const getAllWallets = () => {
    var email = "";
    try {
        const cookies = new Cookies();
        const adtoken = cookies.get("adtoken");
        var dt = JSON.parse(atob(adtoken));
        email = dt.email;
    } catch (err) {
        email = 'admin@mail.com';
    }

    return (dispatch) => {
        axios.post(config.server_url + "/admin/getAllWallets", { email }).then((res) => {
            dispatch({
                type: "WALLETS",
                data: res.data.result,
            });
        });
    };
};

export const sendRewards = (amount, asset, walletId, publicKey, password) => {
    return (dispatch) => {
        axios
            .post(config.server_url + "/admin/sendRewards", {
                amount,
                asset,
                walletId,
                publicKey,
                password,
            })
            .then(async (res) => {
                dispatch({
                    type: "SENDREWARDS",
                    data: res.data.status,
                });
            });
    };
};

export const changePass = (currentPass, newPass, email) => {
    return axios.post(config.server_url + "/admin/changePass", { currentPass, newPass, email });
};

export const getStatisticData = () => {
    return axios.post(config.server_url + "/admin/getStatisticData");
};

export const getTotalBalance = () => {
    return axios.post(config.server_url + "/admin/getTotalBalance");
};

export const setWalletFreeze = (walletId) => {
    return axios.post(config.server_url + "/admin/setWalletFreeze", walletId);
};

export const getRevolutRefreshToken = (data) => {
    return axios.post(config.server_url + "/revolut/getRefreshToken", data);
};

export const getRevolutAccountInfo = (data) => {
    return axios.post(config.server_url + "/revolut/getAccountInfo", data);
};
